import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "info-page__content";

const Content = ({ icon, title, text }) => {
  return (
    <div className={mainClass}>
      <div className="container container--medium">
        <div className={`${mainClass}__left`}>
          <div className={`${mainClass}__left__text`}>
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
          <Link to="/">
            Powrót do strony głównej
            <ArrowIcon />
          </Link>
        </div>
        {icon}
      </div>
    </div>
  );
};

export default Content;
