import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

import NotFoundIcon from "icons/not-found.svg";

const NotFoundPage = ({ location }) => {
  const { pathname } = location;

  return (
    <Layout static_header={pathname === "/"} hide_contact_section>
      <Content
        icon={<NotFoundIcon />}
        title="Błąd 404"
        text="Ta strona nie istnieje"
      />
    </Layout>
  );
};

export const Head = () => <Seo title="404 | Wólczańska 4" />;

export default NotFoundPage;
